// =================== URL APIs ============================
// ============= en caso de incluir traduccion ==================
export const randomNumber = (min_number = 1000000000, max_number = 9999999999, break_number) =>{
	let result;
	do {
		result = Math.floor(Math.random() * (min_number - max_number + 1)) + max_number;
	} while (break_number !== undefined && result === break_number);
	return result;
};
const validInt = (number) => {
	return number == null || isNaN(parseInt(number)) ? 0 : parseInt(number);
};
function bankerRounding(amount, decimalPlaces) {
	
	let decimal_places 			= decimalPlaces || 0;
	let potencia 				= Math.pow(10, decimal_places);
	let avoid_rounding_error 	= +(decimal_places ? amount * potencia : amount).toFixed(8); // Avoid rounding errors
	let int_rounding_error 		= Math.floor(avoid_rounding_error); 
	let decimal_rounding_error 	= avoid_rounding_error - int_rounding_error;
	let allow_rounding_error 	= parseFloat('1e-8'); // Allow for rounding errors in decimal_rounding_error
	
	let rounding_number 		= (decimal_rounding_error > 0.5 - allow_rounding_error && decimal_rounding_error < 0.5 + allow_rounding_error) ? ((int_rounding_error % 2 == 0) ? int_rounding_error : int_rounding_error + 1) : Math.round(avoid_rounding_error);
	
	return decimal_places ? rounding_number / potencia : rounding_number;
};
const formatCurrency = (amount, shopify_rounding = null, locale = "en-US", currency = "USD") => {
	
	if( !amount || amount == null || isNaN( parseFloat( amount ) ) || typeof amount == "string" ){
		
		return { format: "$0.00", number: 0 };
	}
	else{
		
		const formatterCurrency = new Intl.NumberFormat( locale, { style: 'currency', currency: currency, minimumFractionDigits: 2 } );
		const parts_format      = formatterCurrency.formatToParts( 1000 );
		
		let regexp_symbol               = new RegExp(`([${ parts_format[0].value }])`, "g");
		let regexp_separador_millares   = new RegExp(`([${ parts_format[2].value }])`, "g");
		let regexp_separador_decimal    = new RegExp(`([${ parts_format[parts_format.length - 2].value }])`, "g");
		
		if( shopify_rounding != null ){
			
			amount = ( shopify_rounding ? bankerRounding(amount, 2) : amount / 100 );
		}
		let format = formatterCurrency.format( amount );
		
		let number = parseFloat( format.replace( regexp_symbol, "").replace( regexp_separador_millares, "" ).replace( regexp_separador_decimal, "." ) );
		
		return { format, number };
	}
};
const compareSortObject = ( is_asc, data_sort, current_item, next_item ) => {
	
	if( current_item[data_sort] > next_item[data_sort] ){
		
		return is_asc === 'asc' ? 1 : -1;
	}
	else if( current_item[data_sort] < next_item[data_sort] ){
		
		return is_asc === 'asc' ? -1 : 1;
	}
	else{
		
		return 0;
	}
};
export const calcDiscountPrice = ( price, discount, quantity ) =>{
	
	return ( Math.round( price * 100 ) - parseInt( ( ( Math.round( price * 100 ) * discount * quantity ) / 100 ).toString().split(".")[0] ) / quantity );
};
export const formatData = {
	validInt: validInt,
	currency: formatCurrency,
	draftOrder(order_data, apply_discounts, bundle_details){
		
		let line_items = [];
		let skus = [];
		let total_cart = 0;
		let discount_stock = 0;
		let min_stock = 0;
		if( ['cart', 'cart-for-later','pre-order-bundle-complete', 'pre-order-complete'].includes( order_data.product_origin ) ){
			
			for (const item_variant of order_data.shipping_rates.products) {
				
				skus.push( item_variant.sku );
				let line_item 			= {
					variant_id	: item_variant.variant_id,
					quantity	: item_variant.quantity
				};
				let discount_brand 	= item_variant.all_discounts.brand;
				
				if( item_variant.all_discounts.stock.value > 0 && apply_discounts.stock ){
					discount_stock = item_variant.all_discounts.stock.value;
					min_stock = item_variant.all_discounts.stock.min_stock;
					discount_brand                = ( discount_brand + ( ( item_variant.all_discounts.stock.value * ( 100 - discount_brand ) ) / 100 ) );
				}
				let discount_price 		= calcDiscountPrice( item_variant.price, discount_brand, item_variant.quantity );
				if( item_variant.price > 0 && discount_brand > 0 && item_variant.discount_type == 'percentage' ){
					
					let percentage = 0;
					if( item_variant.all_discounts.coupon.percentage ){
						
						percentage = 100 - ( ( 100 - discount_brand ) * ( ( 100 - item_variant.all_discounts.coupon.percentage ) / 100 ) );
						
					}
					else{
						
						percentage = discount_brand;
					}
					
					discount_price = calcDiscountPrice( item_variant.price, 100 - percentage, item_variant.quantity );
					
					line_item.applied_discount = { 
						value_type	: 'percentage', 
						value		: percentage, 
						amount		: formatCurrency( discount_price * parseInt(item_variant.quantity), false ).number 
					};
					total_cart += formatCurrency( discount_price * parseInt(item_variant.quantity), false ).number;
				}
				else if( item_variant.price > 0 && discount_brand > 0 && item_variant.discount_type == 'mixed_amount' ){
					
					line_item.applied_discount = { 
						value_type	: 'fixed_amount', 
						value		: ( ( ( ( item_variant.price - formatCurrency( discount_price, false ).number ) * item_variant.quantity ) + item_variant.all_discounts.coupon.fixed ) / item_variant.quantity ), 
						amount		: ( ( item_variant.price - formatCurrency( discount_price, false ).number ) * item_variant.quantity ) + item_variant.all_discounts.coupon.fixed
					};
					total_cart += formatCurrency( discount_price * item_variant.quantity, false ).number - item_variant.all_discounts.coupon.fixed;
				}
				line_items.push( line_item );
			}
		}
		else if( order_data.product_origin == 'pre-order-bundle-advance' ){
			
			line_items.push({
				title: order_data.shipping_rates.products[0].title,
				sku: order_data.shipping_rates.products[0].sku,
				quantity: order_data.shipping_rates.products[0].quantity,
				price: order_data.shipping_rates.products[0].price
			})
		}
		else if( order_data.product_origin == 'pre-order-advance' ){
			
		}
		let business = order_data.business.find( (item) => item.name == "PLN DISTRIBUTIONS LLC" );
		let tax_lines = []
		if( order_data.shipping_rates.taxes.country != null ){
			
			order_data.shipping_rates.taxes.country.total = ( total_cart * ( order_data.shipping_rates.taxes.country.percentage / 100 ) );
			tax_lines.push( order_data.shipping_rates.taxes.country );
		}
		if( order_data.shipping_rates.taxes.state != null ){
			
			if( order_data.shipping_rates.taxes.state.type == 'normal' ){
				
				order_data.shipping_rates.taxes.state.total = ( total_cart * ( order_data.shipping_rates.taxes.state.percentage / 100 ) );
			}
			else{
				
				order_data.shipping_rates.taxes.state.total = ( order_data.shipping_rates.taxes.country != null ? order_data.shipping_rates.taxes.country.total : total_cart * ( order_data.shipping_rates.taxes.state.percentage / 100 ) );
			}
			tax_lines.push( order_data.shipping_rates.taxes.state );
		}
		tax_lines = tax_lines.map( (item) => {
			
			return {
				price	: item.total,
				rate	: ( item.percentage / 100 ),
				title	: item.name
			};
		});
		let pay_width_balance = order_data.payment_method && order_data.payment_method != 'balance' && order_data.useBalance > 0 ? 'balance-' : '';
		let tags = [
			'ISCATA1OG',
			order_data.product_origin.indexOf('pre-order') >= 0 ? order_data.product_origin.toUpperCase() : '',
			apply_discounts.stock ? 'DISCOUNT-BY-STOCK' : '',
			apply_discounts.stock ? `DISCOUNT-${ discount_stock }-MIN-${ min_stock }` : '',
			`PAYMENT-${ pay_width_balance.toUpperCase() }${ order_data.payment_method.toUpperCase() }`,
			order_data.coupon != null ? `COUPON-${ order_data.coupon.name }` : ''
		].filter( (item) => item != '' );
		let shopify_data = {
			financial_status: "pending",
			tags            : tags.join(', '),
			note_attributes : [
				{
					name    : 'business',
					value   :  business ? business.name : ""
				},
				{
					name    : 'is_dropshipping',
					value   : order_data.customer.is_dropshipping
				},
				{
					name    : 'payment_method',
					value   :  order_data.payment_method ? `${ pay_width_balance }${ order_data.payment_method }` : ""
				},
				{
					name    : 'need_invoice',
					value   :  order_data.need_invoice ? order_data.need_invoice : ""
				},
				{
					name    : 'coupon',
					value   : order_data.coupon != null ? order_data.coupon.name : ""
				},
				{
					name    : 'coupon_id',
					value   : order_data.coupon != null ? order_data.coupon._id : ""
				},
				{
					name    : 'product_origins',
					value   : order_data.product_origin
				}
			],
			tax_lines       : tax_lines,
			billing_address : {
				first_name      : order_data.billing_address.first_name,
				last_name       : order_data.billing_address.last_name,
				name            : order_data.billing_address.name,
				company         : order_data.billing_address.company,
				address1        : order_data.billing_address.address_1,
				address2        : order_data.billing_address.address_2,
				country         : order_data.billing_address.country,
				country_code    : order_data.billing_address.country_code,
				province        : order_data.billing_address.state,
				province_code   : order_data.billing_address.state_code,
				city            : order_data.billing_address.city,
				zip             : order_data.billing_address.zip,
				phone           : order_data.billing_address.phone
			},
			customer        : { id: order_data.customer.shopify_id },
			line_items      : line_items,
			shipping_address: {
				first_name      : order_data.shipping_address.first_name,
				last_name       : order_data.shipping_address.last_name,
				name            : order_data.shipping_address.name,
				company         : order_data.shipping_address.company,
				address1        : order_data.shipping_address.address_1,
				address2        : order_data.shipping_address.address_2,
				country         : order_data.shipping_address.country,
				country_code    : order_data.shipping_address.country_code,
				province    	: order_data.shipping_address.state,
				province_code   : order_data.shipping_address.state_code,
				city            : order_data.shipping_address.city,
				zip             : order_data.shipping_address.zip,
				phone           : order_data.shipping_address.phone
			},
			shipping_line   : order_data.shipping_rates.rates.map( (item) => {                                        
				return {
					custom  : true,
					price   : item.price,
					title   : item.name
				}
			})[0],
			note: order_data.note
		};
		
		if( order_data.bundle ){

			shopify_data.note_attributes.push( {
				name    : 'product_bundle',
				value   : order_data.bundle
			});
		}
		if( order_data.preorder ){
			
			shopify_data.note_attributes.push( {
				name    : 'preorder',
				value   : order_data.preorder
			});
		}
		let draft_data = {
			shopify			: JSON.stringify( shopify_data ),
			customer		: order_data.customer._id,
			coupon			: order_data.coupon != null ? order_data.coupon._id : null,
			product_origin	: order_data.product_origin,
			apply_discounts	: apply_discounts,
			bundle_details	: bundle_details
		}
		return draft_data;
	}
};
export const arrayHelpers = {
	sortArray ( array, data_sort, is_asc = 'asc' ){
		
		return array.length > 0 && array[0][data_sort] != undefined ? array.sort( (current_item, next_item) => { return compareSortObject(is_asc, data_sort, current_item, next_item); }) : array;
	},
	sortArrayProducts ( array, data_sort ){
		
		let sort_values = [{ value: 'best-selling', field: 'default_sort' }, { value: 'price-asc', field: 'min_price' }, { value: 'price-desc', field: 'min_price' }, { value: 'created', field: 'created_at' }];
		let index_sort = sort_values.findIndex( (item) => item.value == data_sort );
		
		if( sort_values[index_sort].field == 'default_sort' ){
			
			array = array.map( (item) => {
				
				return item.sort( (current_item, next_item) => { return compareSortObject('asc', sort_values[index_sort].field, current_item, next_item); });
			}).flat();
			
			return array;
		}
		else{
			
			array = array.flat();
			return array.length > 0 && array[0][sort_values[index_sort].field] != undefined ? array.sort( (current_item, next_item) => { return compareSortObject(data_sort == 'price-desc' ? 'desc' : 'asc', sort_values[index_sort].field, current_item, next_item); }) : array;
		}
		
	},
	chunkArray ( array, chunk_size ){
		
		let results = [];
		while ( array.length ) {
			
			results.push( array.splice( 0, chunk_size ) );
		}
		return results;
	}
};
export const validUploadFile = (file, input_file_name, max_size, old_file_path, extra_data) => {
	let file_types = {
		excel: [".xls", ".xlsx"],
		image: ["png", "jpeg", "jpg", "webp"]
	}
	let name_file = file.name.split("\\")[ file.name.split("\\").length - 1 ];
	let regex = new RegExp(`^([a-zA-Z0-9\s_\\\-: ()-_])+(${ file_types[extra_data.category_file].join("|") })$`);
	
	if ( regex.test( name_file.toLowerCase() ) && file.size <= max_size ) {
		
		let formData = new FormData();
		formData.append( input_file_name, file);
		formData.append( "max_size", max_size );
		formData.append( "old_file_path", old_file_path ? old_file_path : "" );
		
		if( extra_data.body ){
			
			for (const item_data of extra_data.body) {
				
				formData.append( item_data.name, item_data.value );
			}
		}
		return { success: true, data: formData };
	}
	else if ( !regex.test( name_file.toLowerCase() ) ){
		
		return { success: false, title: "Error: Upload File", message: `The selected file type is not as expected (${ file_types[extra_data.category_file].join(', ') }) or file name contains special characters` };
	}
	else{
		
		return { success: false, title: "Error: Upload File", message: "This file weighs more than 100mb, it cannot be uploaded" };
	}
};
export const effectSlider = (id_slider, count_items, ms_delay_effect) => {
	
	if( document.getElementById(id_slider) && document.getElementById(id_slider).getElementsByClassName('show-item-carousel')[0] ){
		
		let name_slider = id_slider.replace('slider-', '');
		
		let current_id 		= document.getElementById(id_slider)?.getElementsByClassName('show-item-carousel')[0].id;
		let current_index 	= parseInt(current_id.replace(`item-carousel-${name_slider}-`, ''));
		let next_index 		= current_index + 1 <= count_items ? current_index + 1 : 1;
		let next_id 		= `item-carousel-${name_slider}-${next_index}`;
		
		if( document.getElementById(next_id) ){
			
			document.getElementById(next_id).classList.add('show-item-carousel');
			document.getElementById(next_id).classList.remove('hide-item-carousel');
			if( document.getElementById(current_id) != null ){
				document.getElementById(current_id).classList.add('hide-item-carousel');
				setTimeout(() => {
					if( document.getElementById(current_id) != null ){
						document.getElementById(current_id).classList.remove('hide-item-carousel','show-item-carousel');
					}
				}, ms_delay_effect);
			}
		}
		
	}
}